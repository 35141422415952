import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Button/Button.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Card"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Card/Card.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Center"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Center/Center.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleGrid"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/SimpleGrid/SimpleGrid.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Text"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Text/Text.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Title"] */ "/vercel/path0/node_modules/@mantine/core/esm/components/Title/Title.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer/footer.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Hero/contact.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Hero/hero.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Hero/marquee.jsx");
